import React from 'react'
import { MainLayout, useLanguage, withLanguageProvider } from '@tmp'
import { NewsArticleTable } from '@cmp'

import { allLocales } from '@src/utils/locale'

import { useNewsData } from '@src/hooks/useNewsData'

const NewsOverviewTemplate = ({ hideHeaders }: { hideHeaders?: boolean }) => {
  const { t, locale, dateFormat } = useLanguage()
  const articles = useNewsData(locale, dateFormat)

  return (
    <MainLayout localizedUrls={allLocales.map(locale => ({ locale, route: { type: 'newsList' } }))}>
      <NewsArticleTable
        title={t('news')}
        numArticles={8}
        translate={t}
        articles={articles}
        hideHeaders={hideHeaders}
      />
    </MainLayout>
  )
}

export default withLanguageProvider(NewsOverviewTemplate)
